import React from 'react';
import s from './FormStatus.module.scss';

const FormStatus = ({ formik, successMessage }) => (
  <>
    {formik.errors.form && (
      <div className={s.errorMessage}>{formik.errors.form}</div>
    )}
    {formik.status && formik.status.success && (
      <div className={s.successMessage}>{successMessage}</div>
    )}
  </>
);

export default FormStatus;
