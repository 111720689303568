export function randomHexColor() {
  // eslint-disable-next-line no-bitwise
  return `#${((Math.random() * 0xffffff) << 0).toString(16)}`;
}

export function postTrimmer(req, res, next) {
  if (req.method === 'POST') {
    for (const [key, value] of Object.entries(req.body)) {
      req.body[key] = value.trim();
    }
  }
  next();
}

export async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  if (!response.ok) {
    const { status, statusText } = response;
    return {
      success: false,
      status,
      statusText,
      url,
      message: `Error ${status}: ${statusText}`,
    };
  }
  return await response.json(); // parses JSON response into native JavaScript objects
}

export const handleResponseNotOk = (response) => {
  const { ok, status, statusText } = response;
  if (ok === false) {
    throw new Error(`Error ${status}: ${statusText}`);
  }
  return response;
};

export const responseToJson = (response) => {
  return response.json();
};

export function maxMessage(max) {
  return `Must be ${max} characters or less`;
}

export function getData(url = '') {
  return fetch(url)
    .then(handleResponseNotOk)
    .then(responseToJson)
    .then((data) => {
      if (!data.isSuccess) throw new Error(data.message);
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });
}

const tabbableNode = /input|select|textarea|button|object/;

export function isContentHidden(element) {
  const zeroSize = element.offsetWidth <= 0 && element.offsetHeight <= 0;

  // If the node is empty, this is good enough
  if (zeroSize && !element.innerHTML) return true;

  // Otherwise we need to check some styles to see if the node's children are visible.
  const style = window.getComputedStyle(element);
  return zeroSize
    ? style.getPropertyValue('overflow') !== 'visible' ||
        // if 'overflow: visible' set, check if there is actually any overflow
        (element.scrollWidth <= 0 && element.scrollHeight <= 0)
    : style.getPropertyValue('display') === 'none';
}

export function isVisible(element) {
  let parentElement = element;
  while (parentElement) {
    if (parentElement === document.body) break;
    if (isContentHidden(parentElement)) return false;
    parentElement = parentElement.parentNode;
  }
  return true;
}

export function isFocusable(element, isTabIndexNotNaN) {
  const nodeName = element.nodeName.toLowerCase();
  const res =
    (tabbableNode.test(nodeName) && !element.disabled) ||
    (nodeName === 'a' ? element.href || isTabIndexNotNaN : isTabIndexNotNaN);
  return res && isVisible(element);
}

export function isTabbable(element) {
  let tabIndex = element.getAttribute('tabindex');
  if (tabIndex === null) tabIndex = undefined;
  const isTabIndexNaN = isNaN(tabIndex);
  return (
    (isTabIndexNaN || tabIndex >= 0) && isFocusable(element, !isTabIndexNaN)
  );
}

/**
 * @function findTabbableDescendants
 * @desc Retrieve an array of all the elements that can be tabbed to by the user. Initially created
 * to help contain tabbing within a modal.
 *
 * @param {object} element a DOM element.
 * @returns {Array} an array of tabbable dom elements or an empty array.
 */
export function findTabbableDescendants(element) {
  return [].slice.call(element.querySelectorAll('*'), 0).filter(isTabbable);
}
