import React from 'react';
import Loader from 'react-loader-spinner';
import s from './Loading.module.scss';
import cn from '../../../helpers/cn';

export default ({ className, ...props }) => (
  <div className={cn(s.loading, className)}>
    <Loader type="ThreeDots" color="white" height={80} width={80} {...props} />
  </div>
);
