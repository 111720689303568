import React from 'react';
import Login from '../../../pages/Login';
import { useAuth } from '../../../pages/Dashboard/hooks';
import Loading from '../Loading';

const PrivateRoute = ({ as: Comp, ...props }) => {
  const { isLoading, isLoggedIn } = useAuth();

  if (isLoading) {
    console.log('isLoading', { isLoading, isLoggedIn }, new Date());
    return <Loading />;
  }

  return isLoggedIn ? <Comp {...props} /> : <Login />;
};

export default PrivateRoute;
