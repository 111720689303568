import React from 'react';
import { Formik } from 'formik';
import { FormField, Button } from '../../form';
import { object, string } from 'yup';
import { navigate } from '@reach/router';
import { postData, handleResponseNotOk } from '../../helpers';
import { useAppDispatch } from '../../App/AppContext';

import s from './Login.module.scss';

const validationSchema = object({
  username: string().required('Login is required'),
  password: string().required('Password is required'),
});

const Login = () => {
  const dispatch = useAppDispatch();

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        console.log('values', values);
        console.log('actions', actions);
        actions.setStatus({ success: null });
        return postData('/authenticate/login', { ...values })
          .then(handleResponseNotOk)
          .then((data) => {
            if (!data.isSuccess) throw new Error(data.message);
            dispatch({ type: 'login' });
            actions.setStatus({ success: true });
            actions.resetForm();
            console.log('logged in, redirect to dashboard');
            navigate('/dashboard');
          })
          .catch((error) => {
            actions.setFieldError('form', error.message);
          });
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} className={s.form}>
          {formik.errors.form && (
            <div className={s.errorMessage}>{formik.errors.form}</div>
          )}
          <FormField id="username" type="text" name="username" label="Login" />
          <FormField
            id="password"
            type="password"
            name="password"
            label="Password"
          />
          <div className={s.buttonRow}>
            <Button type="submit" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? 'LOGGING IN...' : 'Log In'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Login;
