import { useReducer, useEffect } from 'react';
import { handleResponseNotOk, responseToJson } from '../../../../helpers';

const initialState = {
  errorMessage: null,
  isLoading: true,
  isLoggedIn: false,
  isSuccess: null,
  username: null,
};

const reducer = (state, action) => {
  const { type, username, error } = action;
  switch (type) {
    case 'success':
      return {
        ...state,
        errorMessage: null,
        isLoading: false,
        isLoggedIn: true,
        isSuccess: true,
        username,
      };
    case 'failure':
      return {
        ...state,
        errorMessage: error?.message,
        isLoading: false,
        isLoggedIn: false,
        isSuccess: false,
        username: null,
      };

    default:
      return { ...state };
  }
};

const useAuth = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log('useAuth fetching...');
    fetch('/authenticate/checkSession')
      .then(handleResponseNotOk)
      .then(responseToJson)
      .then(json => {
        const { username, isLoggedIn, error } = json;
        console.log('fetch returned results', { username, isLoggedIn, error });
        if (username && isLoggedIn) {
          dispatch({ type: 'success', username });
        } else {
          dispatch({ type: 'failure', error });
        }
      })
      .catch(error => {
        console.log('fetch error', error);
        dispatch({ type: 'failure', error });
      });
  }, []);

  console.log('useAuth state', { ...state });
  return { ...state };
};

export default useAuth;
