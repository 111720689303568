import React from 'react';
import PropTypes from 'prop-types';
import cn from '../../helpers/cn';
import s from './Button.module.scss';

const Button = ({ children, className, ...props }) => (
  <button className={cn(s.button, className)} type="submit" {...props}>
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  className: null,
};

export default Button;
