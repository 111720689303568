import React from 'react';
import { Link } from '@reach/router';
import s from './NotFound.module.scss';

export default () => (
  <div className={s.wrapper}>
    <div className={s.vert}>
      <h1 className={s.title}>Page Not Found</h1>
      <p className={s.paragraph}>
        <Link className={s.navLink} to="/">
          Home
        </Link>
      </p>
    </div>
  </div>
);
