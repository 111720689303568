import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import s from './FormField.module.scss';
import cn from '../../helpers/cn';

const Input = ({ id, name, type, handleChange, value, ...props }) => (
  <input
    className={s.input}
    id={id}
    name={name}
    type={type}
    onChange={handleChange}
    value={value}
    {...props}
  />
);

const Select = ({ id, name, type, handleChange, value, options, ...props }) => (
  <div className={s.select}>
    <select
      className={s.selectElement}
      id={id}
      name={name}
      onChange={handleChange}
      value={value}
      {...props}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
    <div className={s.fakeSelect}>{value}&nbsp;</div>
  </div>
);

Select.defaultProps = {
  value: 'Select an Option',
};

const FormField = ({ isRequired, label, id, type, onFocus, ...props }) => {
  const [field, meta] = useField(props);

  let Comp;

  switch (type) {
    case 'text':
    case 'email':
    case 'password':
    case 'tel':
    case 'url':
    case 'number':
      Comp = Input;
      break;
    case 'select':
      Comp = Select;
      break;
    default:
      break;
  }

  const [isLabelMoved, setIsLabelMoved] = useState(false);

  const handleFocus = (ev) => {
    setIsLabelMoved(true);
    if (typeof onFocus === 'function') onFocus(ev);
  };

  field.onBlur = (ev) => {
    if (!meta.touched && !meta.value) setIsLabelMoved(false);
  };

  const labelClass = cn(
    s.label,
    ((meta.touched && props.value) ||
      meta.error ||
      props.value ||
      isLabelMoved) &&
      s.moveLableUp
  );

  return (
    <label className={s.wrapper} htmlFor={id}>
      <div className={labelClass}>
        <span className={s.labelText}>{label}</span>
        {isRequired && <span className={s.labelRequired}>*</span>}
        {meta.touched && meta.error ? (
          <span className={s.labelError}>{meta.error}</span>
        ) : null}
      </div>
      <Comp id={id} type={type} {...field} {...props} onFocus={handleFocus} />
    </label>
  );
};

FormField.propTypes = {
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
};

FormField.defaultProps = {
  isRequired: false,
  type: 'text',
};

export default FormField;
