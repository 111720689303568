import React, { Suspense } from 'react';
import { Router } from '@reach/router';
import { AppProvider } from '../App/AppContext';
import PrivateRoute from './components/ProtectedRoute';
import './global.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import s from './App.module.scss';
import Loading from './components/Loading';
import NotFound from '../pages/NotFound';

const Home = React.lazy(() => import('../pages/Home'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Login = React.lazy(() => import('../pages/Login'));
const Shop = React.lazy(() => import('../pages/Shop'));
const DashboardHome = React.lazy(() =>
  import('../pages/Dashboard/subpages/DashboardHome')
);
const Shows = React.lazy(() => import('../pages/Dashboard/subpages/Shows'));
const Venues = React.lazy(() => import('../pages/Dashboard/subpages/Venues'));

export default () => {
  return (
    <AppProvider>
      <Suspense fallback={<Loading className={s.loading} />}>
        <Router>
          <NotFound default />
          <Home path="/" />
          <Login path="login" />
          <PrivateRoute as={Dashboard} path="dashboard">
            <DashboardHome path="/" />
            <Venues path="venues" />
            <Shows path="shows" />
          </PrivateRoute>
          <Shop path="shop" />
        </Router>
      </Suspense>
    </AppProvider>
  );
};
