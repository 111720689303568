import React, { useReducer, useContext, createContext } from 'react';

const AppStateContext = createContext();
const AppDispatchContext = createContext();

function appReducer(state, action) {
  const { type } = action;
  switch (type) {
    case 'login':
      return { ...state, isLoggedIn: true };
    case 'logout':
      return { ...state, isLoggedIn: false };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, { isLoggedIn: false }); // isLoggedIn should check session first.
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const state = useContext(AppStateContext);
  if (typeof state === 'undefined') {
    throw new Error('useAppState must be used within AppProvider');
  }
  return state;
}

function useAppDispatch() {
  const dispatch = useContext(AppDispatchContext);
  if (typeof dispatch === 'undefined') {
    throw new Error('useAppDispatch must be used within AppProvider');
  }
  return dispatch;
}

export { AppProvider, useAppDispatch, useAppState };
