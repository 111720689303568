import React from 'react';
import { Formik } from 'formik';
import { postData } from '../../helpers';

const FormikTemplate = ({
  apiEndpoint,
  validationSchema,
  handleSuccess,
  initialValues,
  children,
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={(values, formikBag) => {
      formikBag.setStatus({ success: null });
      postData(apiEndpoint, { ...values })
        .then(data => {
          if (data.isSuccess) {
            formikBag.setStatus({ success: true });
            formikBag.resetForm();
            if (handleSuccess) handleSuccess(data);
          } else {
            formikBag.setFieldError('form', data.message);
          }
        })
        .catch(error => {
          formikBag.setFieldError('form', error.message);
        });
    }}
  >
    {children}
  </Formik>
);

export default FormikTemplate;
